import {
    // AcademicCapIcon,
    // ArrowDownTrayIcon,
    // BuildingOffice2Icon,
    // CalendarIcon,
    // FlagIcon,
    // MapIcon,
    // SparklesIcon,
    UsersIcon,
    ChartPieIcon,
    BoltIcon,
    UserGroupIcon,
    CurrencyDollarIcon,
    // FireIcon,
    // GlobeAmericasIcon,
    RocketLaunchIcon,
    CircleStackIcon,
    FireIcon,
  
  } from '@heroicons/react/24/outline';

//   import Image from 'react-images/';
  import Icon from 'react';

  import profilepic from '../images/Profile.png';






export const profData = {
    profileImageSrc: profilepic,
    description: `Mine ShibDNA Tokens Easily and earn instant Rewards of $5 for each new member you invite.
  
  Enjoy the benefits of Instant Withdrawals and real-time access to legitimate balances and data on the blockchain. `,
    aboutItems: [
      {label: 'Circulating Supply', text: '10000000', Icon: ChartPieIcon},
      {label: 'Burnt Supply', text: '360 Billion', Icon: FireIcon},
      {label: 'Total Supply', text: '720 Billion', Icon: CircleStackIcon},
      {label: 'Miners', text: '3702', Icon: UserGroupIcon},
      {label: 'Mining Stage', text: '1', Icon: RocketLaunchIcon},
      {label: 'Mining Speed' , text: '23400 /Day', Icon: BoltIcon},
      {label: 'Your Balance', text: '56000 TORA', Icon: CurrencyDollarIcon},
      {label: 'Friends Invited', text: '5', Icon: UsersIcon},
  
    ],
  }; 


 const Profile = () => {
    const {profileImageSrc, description, aboutItems} = profData;
      return(


        <div className="bg-neutral-800 bg-cover w-full h-full p-5 ">
        <div className='grid grid-cols-1 gap-y-4 md:grid-cols-4 mt-10 mb-20'>
          {!!profileImageSrc && (
            <div className="col-span-1 flex justify-center md:justify-start">
              <div className="relative h-24 w-24 overflow-hidden rounded-xl md:h-32 md:w-32">
                <img alt="about-me-image" className="h-full w-full object-cover" src={profileImageSrc} />
              </div>
            </div>
          )}
          <div className='col-span-1 flex flex-col gap-y-6 md:col-span-3' >
            <div className="flex flex-col gap-y-2">
              <h2 className="text-2xl font-bold text-violet-500">Profile</h2>
              <p className="prose prose-sm text-gray-300 sm:prose-base">{description}</p>
            </div>
            <ul className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {aboutItems.map(({label, text, Icon}, idx) => (
                <li className="col-span-1 flex  items-start gap-x-2" key={idx}>
                  {Icon && <Icon className="h-5 w-5 text-violet-400" />}
                  <span className="text-sm font-bold text-violet-400 ">{label}:</span>
                  <span className=" text-sm text-gray-300">{text}</span>
                </li>
              ))}
            </ul> 
          </div>
        </div>
      </div>

      );




  }

  export default Profile;