import {FC, memo, PropsWithChildren, useMemo} from 'react';





const Stages = [
    {
      name: 'Mine 1 (Stage 1)',
      skills: [
        {
          name: 'Base Speed: 1000/Hour',
          level: 10,
        },
        {
          name: 'Mineable Supply: 10 Billion',
          level: 1,
        },
        {
          name: 'Estimated Price: 0.0001 USD',
          level: 1,
        },
      ],
    },
    {
      name: 'Mine 2 (Stage 2)',
      skills: [
        {
          name: 'Base Speed: 500/Hour',
          level: 5,
        },
        {
          name: 'Mineable Supply: 20 Billion',
          level: 2,
        },
        {
          name: 'Estimated Price: 0.0005 USD',
          level: 3,
        },
      ],
    },
    {
      name: 'Mine 3 (Stage 3)',
      skills: [
        {
          name: 'Base Speed: 250/Hour',
          level: 3,
        },
        {
          name: 'Mineable Supply: 50 Billion',
          level: 4,
        },
        {
          name: 'Estimated Price: 0.0015 USD',
          level: 5,
        },
      ],
    },
    {
      name: 'Mine 4 (Stage 4)',
      skills: [
        {
          name: 'Base Speed: 125/Hour',
          level: 2,
        },
        {
          name: 'Mineable Supply: 80 Billion',
          level: 7,
        },
        {
          name: 'Estimated Price: 0.003 USD',
          level: 6,
        },
      ],
    },
    {
      name: 'Mine 5 (Stage 5)',
      skills: [
        {
          name: 'Base Speed: 62.5/Hour',
          level: 1,
        },
        {
          name: 'Mineable Supply: 100 Billion',
          level: 10,
        },
        {
          name: 'Estimated Price: 0.01 USD',
          level: 10,
        },
      ],
    },
  ];

  const GuideLine = [
    {
      date: '$5 Plus 5000 $DNA',
      location: 'Invitation Rewards',
      title: 'How to Invite Friends?',
      content: <p>You get $5 Instantly as soon as someone registers using your wallet address as referral code</p>,
    },
    // {
    //   date: '0.01 BNB (~$5)',
    //   location: '5000 $DNA Token',
    //   title: 'How to Claim Referral Rewards?',
    //   content: <p>You don't need to claim your rewards because it will be transfered to your wallet as soon as someone starts mining using your wallet address as referral code </p>,
    // },
  ];

  const Details =  [
    {
      date: 'Mining Squad',
      location: 'Referral Reward',
      title: 'How Mining Works?',
      content: (
        <div className="Markdown_markdownContainer__Tz3HQ">
  <p>The ShibSquad SmartContract is a decentralized application (dApp) built on Binance Smart Chain. When you register with the ShibSquad SmartContract, it will associate your blockchain wallet address with a user profile.</p><br/>
  <p>As a newly registered miner, the SmartContract will automatically assign you a base mining speed of 1,000 $DNA per hour. This means that by simply being a registered miner, you will start earning $DNA at a rate of 1,000 per hour.</p><br/>
  <p>However, you can increase your mining speed beyond the initial 1,000 $DNA/hour by <strong>Unlocking Mining Characters and Upgrading them.</strong>The more characters you unlock and upgrade, the higher your total mining output and $DNA earnings.</p><br/>
  </div>
      ),
    },
    {
      date: 'Add $SDNA to Your Wallet',
      location: 'Claim Your Tokens',
      title: 'How to Claim $DNA?',
      content: (
  
        <div>
          <p><strong>Claim your mined tokens</strong></p><br/>
          <p>The ShibSquad SmartContract has made this process super easy and fast.</p>
          <p>Whenever you want you can have your tokens in your wallet! All you need to do is click the "Claim" button in the SmartContract interface.
          </p><br/>
          <p><strong>Pay for the transaction</strong></p><br/>
          <p>When you click Claim, you will need to pay a small transaction fee to the network.
          </p>
          <p>This allows the SmartContract to instantly send your mined $DNA tokens to your connected wallet.</p><br/>
          <p><strong>Add the $DNA token to your wallet</strong></p><br/>
          <p>After claiming your tokens, make sure you have added the $DNA token to your wallet using the contract address.
  
          </p>
          <p>This will allow you to see and manage your earned $DNA tokens directly from your wallet.</p><br/>
        </div>
      ),
    },
  ];


  //  const Skill: FC<{skill: SkillType}> = memo(({skill}) => {
  //   const {name, level, max = 10} = skill;
  //   const percentage = useMemo(() => Math.round((level / max) * 100), [level, max]);
  
  //   return (
  //     <div className="flex flex-col">
        
  //       <span className="ml-2 text-sm font-medium">{name}</span>
  //       <div className="h-5 w-full overflow-hidden rounded-full bg-neutral-300">
  //         <div className="h-full rounded-full bg-lime-600" style={{width: `${percentage}%`}} />
  //       </div>
  //     </div>
  //   );
  // });

  // export const skillGroup = memo(({Stages}) => {
  //   const {name, skills} = skillGroup;
  //   return (
  //     <div className="flex flex-col">
  //       <span className="text-center text-lg font-bold">{name}</span>
  //       <div className="flex flex-col gap-y-2">
  //         {skills.map((skill, index) => (
  //           <skill key={`${skill.name}-${index}`} skill={skill} />
  //         ))}
  //       </div>
  //     </div>
  //   );
  // });
  
  
const Guide = () => {

  const {name, level, max = 10} = Stages;
    const percentage = useMemo(() => Math.round((level / max) * 100), [level, max]);
  

  return (
   <div className=' p-10 md:p-20 bg-zinc-900 '>


      {GuideLine.map((Props) => (
        <div className="flex flex-col pb-8 text-center text-gray-300 last:pb-0 md:text-left">
        <div className="flex flex-col pb-4">
          <h2 className="text-xl font-bold">{Props.title}</h2>
          <div className="flex items-center justify-center gap-x-2 md:justify-start">
            <span className="flex-1 text-sm font-medium italic sm:flex-none">{Props.location}</span>
            <span>•</span>
            <span className="flex-1 text-sm sm:flex-none">{Props.date}</span>
          </div>
        </div>
        {Props.content}
      </div>

))} 
    {Details.map((Props) => (
        <div className="flex flex-col pb-8 text-center text-gray-300 last:pb-0 md:text-left">
        <div className="flex flex-col pb-4">
          <h2 className="text-xl font-bold">{Props.title}</h2>
          <div className="flex items-center justify-center gap-x-2 md:justify-start">
            <span className="flex-1 text-sm font-medium italic sm:flex-none">{Props.location}</span>
            <span>•</span>
            <span className="flex-1 text-sm sm:flex-none">{Props.date}</span>
          </div>
        </div>
        {Props.content}
      </div>

))}





<div className="flex flex-col">
        <span className="text-center text-lg text-gray-300 font-bold">{name}</span>
        <div className="flex flex-col gap-y-2">
          {Stages.map((skill, index) => (
            <skill key={`${skill.name}-${index}`} skill={skill} />
          ))}
        </div>
      </div>
      {Stages.map((Props) => (
          
         <div className="flex flex-col text-gray-300 ">
        <span className="ml-2 text-sm font-medium">{Props.name}</span>
        <div className="h-5 w-full overflow-hidden rounded-full bg-neutral-300">
          <div className="h-full rounded-full bg-lime-600" style={{width: `${percentage}%`}} />
        </div>
      </div>

))}

</div>


  );



}


export default Guide;