// src/HomePage.js

import React from 'react';
import './HomePage.css'; // Import CSS for animations
import backgroundVideo from'../images/homebg.webm'; //

const HomePage = () => {
  // Replace with a random GIF URL or use a GIF API

  return (
    <div className="relative bg-gray-950 w-full h-screen overflow-hidden">
    <video
          className="absolute   h-full w-full"
          src={backgroundVideo}
          autoPlay
          loop
          muted
        />
      <div className="absolute  inset-0 bg-black opacity-50"></div>
      <div className="relative flex flex-col justify-center backdrop-blur-sm bg-zinc-500/30 items-center rounded-2xl text-white text-center p-10 m-10 mt-40">
        <h3 className="text-3xl font-bold mb-4 text-violet-500 transform transition duration-500 hover:scale-105 animate-fadeIn">
          ToraDeFi
        </h3>
        <>
      <p className="prose-sm text-lime-200 text-start sm:prose-base lg:prose-lg">
      <strong className="text-gray-300"> Mine TORA DeFi </strong> and earn an instant 
        <strong className="text-lime-300"> Reward of $5 </strong> 
        for each new member you invite.
      </p>
      <p className="prose-sm text-lime-200 text-start sm:prose-base lg:prose-lg">
      Enjoy the benefits of  <strong className="text-lime-300"> Instant Withdrawals </strong>
      with real-time Data on the blockchain.</p>
      <p> <br />
        <strong className="text-violet-500 "> Join us in building a thriving community where everyone can succeed!</strong>
      </p>
    </>
        <a href="/Mining">
        <button  className="px-6 py-3 mt-5 bg-violet-500 rounded-md hover:bg-lime-600 transition animate-fadeIn delay-400">
          Start Mining
        </button>
        </a>
      </div>
    </div>
  );
};

export default HomePage;