import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { FaHome, FaUserCircle, FaServicestack, FaUserAstronaut , FaUserPlus } from 'react-icons/fa';
import HomePage from './components/HomePage';
import Mine from './components/Mine';
import './index.css';
import './components/HomePage.css';
import './components/Mine.css';
import './components/Profile';
import Profile from './components/Profile';
import Guide from './components/Guide';
import { Gi3dGlasses, Gi3dHammer, GiMadScientist, GiMineExplosion, GiMining, mining,GiWhiteBook  } from "react-icons/gi";
import { UserCircleIcon } from '@heroicons/react/24/outline';



// const Home = () => <home/>;
// const About = () => <div className="p-4"><h2 className="text-2xl font-bold">About Content</h2></div>;
const Mining = () => {
  return (
    <div className="bg-zinc-950 bg-opacity-95 min-h-screen min-w-screen flex  justify-center">
      {/* <h1 className="text-center text-3xl font-bold text-violet-500 hover:text-lime-500 ">Image Carousel</h1> */}
      <Mine />
    </div>
  );
};
// const Services = () => <div className="p-4"><h2 className="text-2xl font-bold">Services Content</h2></div>;
// const Guide = () => <div className="p-4"><h2 className="text-2xl font-bold">Guide Content</h2></div>;
const FAQ = () => <div className="p-4"><h2 className="text-2xl font-bold">FAQ Content</h2></div>;

const App = () => {
  return (
    <Router >
    <div className="flex flex-col h-screen ">
      <div className=" flex-1    ">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Mining" element={<Mining />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="/faq" element={<FAQ />} />
        </Routes>
      </div>
      <nav className="bg-zinc-900 p-4 max-w-xl sticky bottom-0 z-[9]">
        <div className="flex justify-around">
          <Link className="text-violet-500 hover:text-lime-500 justify-center text-center" to="/">
            <div><FaHome className='ml-3'/></div>
            <span><p className='text-sm'>Home</p></span>
          </Link>
          <Link className="text-violet-500 hover:text-lime-500 text-center" to="/Mining">
            <div><FaUserAstronaut className='ml-3'/></div>
            <span><p className='text-sm'>Heroes</p></span>
          </Link>
          <Link className="text-violet-500 hover:text-lime-500 text-center" to="/profile">
            <div><FaUserCircle className='ml-3' /></div>
            <span><p className='text-sm'>Profile</p></span>
          </Link>
          <Link className="text-violet-500 hover:text-lime-500 text-center" to="/guide">
            <div><GiWhiteBook className='ml-2' /></div>
            <span><p className='text-sm'>Guide</p></span>
          </Link>
          <Link className="text-violet-500 hover:text-lime-500 text-center" to="/faq">
            <div><FaUserPlus className='ml-3' /></div>
            <span><p className='text-sm'>Invite</p></span>
          </Link>
        </div>
      </nav>
    </div>
  </Router>
  );
};

export default App;