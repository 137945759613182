// src/Mine.js

import React from 'react';
import Slider from 'react-slick';
import './Mine.css'; // Import the CSS for styling
import Hero1 from '../images/Heroes/rover.webp'
import Hero2 from '../images/Heroes/meteoric.webp'
import Hero3 from '../images/Heroes/astro.webp'
import Hero4 from '../images/Heroes/quantum.webp'
import Hero5 from '../images/Heroes/gravimetric.webp'
import Hero6 from '../images/Heroes/aurora.webp'
import Hero7 from '../images/Heroes/celestial.webp'
import Hero8 from '../images/Heroes/nebula.webp'
import Hero9 from '../images/Heroes/ionized-Miner.webp'
import Hero10 from '../images/Heroes/Hydra.webp'
import backgroundVideo from '../videos/backgr.webm';

import rights from '../images/ri.webp';
import lefts from '../images/lefts.webp';


const images = [
  {
    src: Hero1,
    name: 'Rover',
    description: ' Extracts Torabium from underground caverns.',
  },
  {
    src: Hero2,
    name: 'Meteoric',
    description: 'Scrapes Torabium from the surfaces of meteors.',
  },
  {
    src: Hero3,
    name: 'Astro',
    description: 'Drills deep into planets to extract Torabium.',
  },
  {
    src: Hero4,
    name: 'Quantum',
    description: 'Scans underground to find Torabium veins.',
  },
  {
    src: Hero5,
    name: 'Gravimetric',
    description: 'Locates Torabium in planetary cores using gravity waves.',
  },
  {
    src: Hero6,
    name: 'Aurora',
    description: ' Collects Torabium from particles in auroras.',
  },
  {
    src: Hero7,
    name: 'Celestial',
    description: 'Uses plasma cutters to mine Torabium from asteroids.',
  },
  {
    src: Hero8,
    name: 'Nebula',
    description: 'Filters cosmic dust in gas clouds for Torabium.',
  },
  {
    src: Hero9,
    name: 'Ionized_Miner',
    description: 'Harvests Torabium from unstable, volatile planets.',
  },
  {
    src: Hero10,
    name: 'Hydra',
    description: 'Mines Torabium from frozen comets.',
  },
];

const Mine = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
  
    return (
      <div className="relative  items-start w-xl h-full sm:max-w-xl overflow-hidden">
        <div className="items-stretch w-full h-full  overflow-hidden">
            <div className=" absolute grid grid-cols-2  w-full mt-5 justify-center  overflow-hidden z-[2]" >
             
             <div className=" flex w-full   " ><img className='h-full w-full opacity-90 ' alt='' src={rights}/></div>
             {/* <div className=" flex w-full " ></div> */}
             {/* <div className=" flex w-full " ></div> */}
             {/* <div className=" flex w-full " ></div> */}
             {/* <div className=" flex w-full   " ><img className='h-full w-full ' alt='' src={lefts}/></div> */}

            </div>
            <div className=" absolute grid grid-cols-7  w-full mt-5 items-start overflow-hidden z-[3]" >

                <p className="flex font-bold text-xs text-violet-500 p-4 mt-2 sm:text-xl sm:p-7">SPEED</p>
                <p className=" flex font-bold text-xs text-lime-500  mt-6 pl-3 sm:text-xl sm:p-3 sm:ml-2">34500</p>
                <p className=" flex font-bold text-xs text-lime-500  mt-6 pr-2 sm:text-xl sm:mt-9">TORA/Day</p>


            </div>
        <video
          className="absolute   z-[1]"
          src={backgroundVideo}
          autoPlay
          loop
          muted
        />
   
        
        </div>

        <Slider {...settings} className="relative sm:max-w-xl object-cover z-[3] mb-5 ">
          {images.map((image, index) => (
            <div key={index} className="flex items-center justify-center p-5 mt-20 ">
              <img src={image.src} alt={`Slide ${index + 1}`} className="relative rounded-lg justify-center h-30% w-50% p-20 pt-10  mt-16 sm:p-50 sm:mt-40" />

              <div className="   w-full flex justify-center sm:mt-16  z-[1]" >
             
             <div className=" flex w-screen   " >
              <img className='h-full w-full mt-14 opacity-60 ' alt='' src={lefts}/>
             
             
             <div className=" absolute flex justify-center w-screen   mt-20   z-[3] " >

             <div className="flex grid grid-row-4 text-center mt-4 justify-self-center sm:p-10  text-white">
                <h2 className="text-xl sm:text-3xl justify-self-center text-violet-500 font-bold ">{image.name}</h2>
                <p className="flex text-sm sm:text-2xl justify-self-center text-lime-400 mb-2 max-w-60 sm:max-w-md ">{image.description}</p>

                <div className="flex h-10   sm:text-17 text-xs justify-center gap-x-6 dark:text-white mb-3">
                <button className="shadow__btn text-lime-500 font-bold  ">
      Unlock {image.name}
</button>
            
       
    
</div>

              </div>
             </div>
             
             </div>
             {/* <div className=" flex w-full " ></div> */}
             {/* <div className=" flex w-full " ></div> */}
             
            </div>

            </div>
          ))}
        </Slider>

        <div className="   w-full justify-center overflow-hidden z-[1]" >
             
             <div className=" flex w-full   " ><img className='h-full w-full mt-5 opacity-0 ' alt='' src={lefts}/></div>
             {/* <div className=" flex w-full " ></div> */}
             {/* <div className=" flex w-full " ></div> */}
             {/* <div className=" flex w-full " ></div> */}
             {/* <div className=" flex w-full   " ><img className='h-full w-full ' alt='' src={lefts}/></div> */}

            </div>

      </div>
    );
  };
  
  export default Mine;